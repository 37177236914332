@charset 'utf-8'
@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaijaan:400,700|Exo+2:400,700')

// Core Variables
$primary: #4ad9df
$info: #413594
$success: #20BC44
$family-sans-serif: 'Exo 2', sans-serif

$base-col: #2e4e72
$accent-col-1: #c6dc94
$accent-col-2: #54b276

// Navbar variables
$navbar-item-color: #b9ccdb
$navbar-item-hover-color: lighten($navbar-item-color, 30%)
$navbar-background-color: transparent
$navbar-item-img-max-height: 3rem
$navbar-padding-horizontal: 3rem
$navbar-burger-color: white

// Breadcrumb nav variables
$breadcrumb-item-color: lighten($base-col, 10%)
$breadcrumb-item-hover-color: lighten($base-col, 40%)
$breadcrumb-item-active-color: lighten($base-col, 30%)
$breadcrumb-item-separator-color: lighten($base-col, 10%)

@import "~bulma/bulma.sass"



// **********************************
//  Global styles & helpful classes
// **********************************
html
  background-color: $base-col
  a
    transition: all 0.3s ease

.av-brand
  font-family: 'Baloo Bhaijaan', cursive

.one-third
    min-height: 33vh
    background: linear-gradient(172deg, $base-col 40%, $accent-col-1 140%)

.gradient-section
  min-height: 95vh
  background: linear-gradient(172deg, $base-col 40%, $accent-col-1 140%)

.iso-tilt-left
  box-shadow: -25px 12px 15px darken($base-col, 10%)
  transform: rotate(-5deg) skewX(-5deg) skewY(-5deg)
.iso-tilt-right
  box-shadow: 25px 12px 15px darken($base-col, 10%)
  transform: rotate(3deg) skewX(3deg) skewY(3deg)

.section .button:hover
  transform: translateY(-2px)
  box-shadow: 2px 2px 2px desaturate($base-col, 10%)

.column
  margin-bottom: 3rem

.dappstore
    height: 100%

// *********************
//  Nav Component
// *********************
nav
  margin-top: 1.8rem
  .navbar-item
    font-size: 1.2rem
    font-family: 'Exo 2', sans-serif
    padding: 0rem 1rem
    margin-right: 1.5rem
    &.text
      border-bottom: 0px solid
      &:hover
        border-bottom: 0.4rem solid
  .tagline
    font-family: 'Baloo Bhaijaan', cursive
    position: absolute
    bottom: -1.2rem
    left: 4.1rem
    font-size: 0.75rem
  .button:hover
    // border: 4.8rem solid

  &.breadcrumb
    margin-left: 5rem
    text-transform: uppercase

body > nav.breadcrumb.has-bullet-separator.is-medium > ul > li.is-active
  color: red

// *********************
//  Footer component
// *********************
.footer
  background-color: darken(desaturate($base-col, 10%), 10%)
  border-top: 15px solid darken(desaturate($base-col, 5%), 5%)
  color: lighten($base-col, 50%)
  .column
    padding: 2vw 5vw
  hr
    background-color: #42576f
    margin-right: 5rem
  ul
    margin-left: 1rem
    a
      color: lighten(desaturate($base-col, 20%), 10%)
      &:hover
        color: lighten($base-col, 20%)

// *********************
//  Home page
// *********************
section.hero
  min-height: 95vh
  background: linear-gradient(172deg, $base-col 40%, $accent-col-1 140%)
  overflow: hidden
  .hero-body
    h1
      text-shadow: 4px 3px 10px #1c2b34
    .columns
      align-items: center
  a
    margin-top: 1rem
    margin-left: 1rem
    font-weight: 700
    &:hover
     // border: 0.8rem solid
  img
    filter: drop-shadow(-15px 25px 25px rgba(0,0,0,0.5))

  .hero-image
    position: relative
    float: left
    .hero-badge
      position: absolute
      top: 80%
      left: 100%
      width: 15%
    @media (min-width: 1408px)
      .badge-animate
        animation: connect 1s 1s forwards cubic-bezier(0.42, 1.25, 0.87, 0.85)
        z-index: 1
        @keyframes connect
          from
            transform: scale(1)

          to
            transform: scale(0.4) translateX(-510px) translateY(-240px)
      .hero-box
        position: relative
        left: -5rem
    &::before
      content: ''
      background: url('../assets/hero-stripes-v1.0.svg') no-repeat
      position: absolute
      top: -5rem
      left: -15rem
      width: 200vw
      height: 200vh


#highlights
  background: linear-gradient(172deg, #335073 40%, $accent-col-2 140%)
  padding-top: 15vh
  border-top: 15px solid $base-col
  .columns
    align-items: center
    img
      filter: drop-shadow(15px 20px 20px darken($base-col, 15%))

#newsletter-cta
  background: lighten(desaturate($base-col, 20%), 20%)
  .card
    background: lighten($base-col, 60%)
    box-shadow: 5px 5px 10px #17394b
    transition: all 0.5s ease
    a
      background-color: darken($primary, 20%)

// *********************
//   Order page
// *********************
.order
  margin-top: 6vh
  min-height: 100vh
  #product-info
    background: linear-gradient(165deg, #2e4e72 40%, #65936b 140%)
    min-height: 100vh
    .columns
      .column
        img
          border: 10px solid darken($base-col, 10%)
        .panel
          margin: 3rem 1rem
          background-color: lighten(desaturate($base-col, 15%), 30%)
          width: 70%
        .pricing-notes
          font-size: 0.8rem
          .button
            margin-top: 2rem
  #order-form
    min-height: 100vh
    .card
      background-color: lighten(desaturate($base-col, 20%), 60%)
      box-shadow: 10px 10px 15px darken($base-col, 10%)
      .button
        box-shadow: 2px 2px 4px lighten($base-col, 40%)
        &:hover
          transition: all 0.1s linear
          transform: translateY(-2px)
          box-shadow: 4px 4px 8px lighten($base-col, 20%)
  #order-thanks
    min-height: 100vh
    overflow-wrap: break-word
    .card
      box-shadow: 5px 5px 5px darken($base-col, 10%)
    .card-body
      p
        padding: 2rem

.ethlogo
    max-height: 300px

.panel
    margin: 3rem 1rem
    background-color: #819bb8
    width: 70%

section .button
    margin-top: 1rem
    margin-left: 1rem
    font-weight: 700