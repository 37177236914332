.nft {
  background-color: rgba(0, 0, 0, 0.1);
  padding:  1em;

}

.nft .level:not(:last-child) {
  margin-bottom: 0.1em;
}

figure.small .p5Canvas {
  width: 48px !important;
  height: 48px !important;
}

/* .large {
  width: 256px !important;
  height: 256px !important;
} */

.max512 {
  max-width: 512px;
  
}