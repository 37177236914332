.plancontainer {
  padding: 1rem;
}

.strike {
  text-decoration: line-through;
  opacity: 0.5;
}

.plan hr {
  border: none;
  display: block;
  height: 2px;
  margin: 1.5rem 0;
  background-color: rgba(99, 165, 187, 0.45);
}

.plan {
  background-color: rgba(40, 74, 110, 0.52);
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.5);
  color: #fbfbfb;
  transition: all 0.2s ease;
}

.plan .trial {
  padding-top: 1rem;
}
/* 
.plan:hover {
  background-color: rgba(61, 113, 168, 0.52);
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.5);
  color: #fbfbfb;
  transition: all 0.2s ease;
} */

.plan .title {
  color: #a7e9bc;
  letter-spacing: 1px;
  text-shadow: 2px 2px 3px #143a4c;
}
.plan .subtitle {
  color: #a7e9bc;
}

.plan .price {
  color: #13dca8;
  /* font-family: "Exo 2", sans-serif; */
  font-size: 3rem;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

.plan .button {
  background-color: #33c1b4;
  border-color: transparent;
  box-shadow: 2px 2px 4px #396876;
  margin-top: 1rem;
  margin-left: 0em;
  margin-bottom: 1rem;
  color: white;
}

.mint .tile.is-child {
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
}

.mint .tile .box {
  background-color: rgba(61, 113, 168, 0.52);
}
.mint .tile .box a {
  color: white;
}